<template>
  <div>
    <label>
      <span v-if="label" class="form-label text-ts-front-label">{{ label }}:</span>
      <textarea
        ref="input"
        v-bind="$attrs"
        class="mt-1 w-full rounded-lg border bg-ts-front-field-light p-3 text-gray-700 focus:border-ts-front-border-dark focus:text-gray-800 focus:outline-none"
        :class="error ? 'border-ts-front-border-error' : 'border-ts-front-border-light'"
        :rows="rows"
        type="text"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </label>
    <div v-if="error" class="form-error text-ts-front-label-error">{{ error }}</div>
  </div>
</template>

<script>
import autosize from 'autosize'

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, null],
      required: true,
    },
    label: String,
    error: String,
    autosize: Boolean,
    rows: {
      type: Number,
      default: 4,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (this.autosize) {
      autosize(this.$refs.input)
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
  },
}
</script>
