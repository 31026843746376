<template>
  <div>
    <label class="form-label font-semibold text-ts-front-label">{{ label }}:</label>
    <div class="mt-1 w-full pb-1 pr-3 text-ts-front-label" :value="modelValue">{{ modelValue }}</div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    label: String,
  },
}
</script>
